<template>
    <div class="reservation">
        <StartServiceLightbox :orderId="currentOpenId" :time="currentTime" />
        <div class="top">
            <Logo />
            <TopTab />
        </div>
        <div class="index-container">
            <div class="title d-none d-xl-block">
                本日預約
                <div v-if="isRelease" class="release-show">此為測試站，請至正式站操作</div>
            </div>
        </div>
        <div class="wrap">
            <BaseSelect
                title="芳療師"
                rootClass="aroma-selector"
                name="selected_aroma"
                :options="aromatherapistList"
                isHasOptionAll
            />
            <transition appear name="fade" v-if="isListRequest">
                <NoData v-show="reservationList == undefined" />
            </transition>
            <transition appear name="fade" v-if="isListRequest">
                <div class="row reserve-container" v-show="reservationList !== undefined">
                    <div class="reserve-wrap">
                        <div class="order-list-wrap">
                            <div class="order-title">本日預約<div v-if="isRelease" class="release-show">此為測試站，請至正式站操作</div></div>
                            <div class="row order-list-header">
                                <div class="col-2 text-center">服務時間</div>
                                <div class="col-1 text-center">客戶</div>
                                <div class="col-2 col-2-less text-center">連絡電話</div>
                                <div class="col-2">服務資訊</div>
                                <div class="col-1">芳療師</div>
                                <div class="col-2 text-center">備註</div>
                                <div class="col-2 col-2-more"></div>
                            </div>
                            <div
                                class="row order-list"
                                v-for="reservation in reservationList"
                                :key="reservation.id"
                                :class="{
                                    active: reservation.is_show_color == 1,
                                }"
                                @click="goToAroma(reservation)"
                            >
                                <div
                                    class="active-icon"
                                    v-show="
                                        reservation.is_show_color == 1
                                    "
                                >
                                    <img src="@/assets/tgd/reserve/date.svg" alt=""/>
                                </div>
                                <div class="col-2 text-center">
                                    <div class="flex_wrap">
                                        {{ reservation.time }}
                                        <div class="inline-data" v-show="reservation.reserve_type == 2">
                                            <img src="@/assets/tgd/reserve/reserve.svg" alt=""/>
                                            <div class="info" v-show="reservation.memo_external != ''">
                                                <img src="@/assets/tgd/reserve/remark.svg" alt=""/>    
                                                <div class="info_content">
                                                    {{ reservation.memo_external }}
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-1 text-center">
                                    {{ reservation.customer_name }}
                                    ({{ reservation.member.length}})
                                </div>
                                <div class="col-2 col-2-less text-center tel-color">
                                    {{ reservation.customer_phone }}
                                </div>
                                <div class="col-2 d-flex flex-column">
                                    <span
                                        class="py-1"
                                        v-for="(service, index) in reservation.service"
                                        :key="index"
                                    >
                                        {{ service }}
                                    </span>
                                </div>
                                <div class="col-1 d-flex flex-column">
                                    <span
                                        class="py-1"
                                        v-for="(member, index) in reservation.member"
                                        :key="index"
                                    >
                                        {{ member.member_name }}
                                        <span
                                            class="badge-color"
                                            v-if="member.type"
                                        >
                                            老點
                                        </span>
                                    </span>
                                </div>
                                <div class="col-2 text-center tel-color">
                                    {{ reservation.customer_remark }}
                                </div>
                                <div class="col-2 col-2-more d-flex justify-content-center">
                                    <button
                                        type="button"
                                        class="btn btn-checkout"
                                        @click.stop="openLightbox(reservation.id, reservation.time)"
                                        v-if="reservation.is_show_inline_cancel == 0"
                                    >
                                        開始服務
                                    </button>
                                    <div
                                        class="btn btn_inline_cancel"
                                        v-if="reservation.is_show_inline_cancel == 1"
                                    >
                                        線上取消
                                    </div>
                                    <button
                                        type="button"
                                        class="btn btn-edit"
                                        v-if="reservation.reserve_type == 1"
                                        @click.stop="
                                            cancelReservation({
                                                reserve_id: reservation.id,
                                                store_id: storeId,
                                            })
                                        "
                                    >
                                        取消預約
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-edit"
                                        v-else
                                        @click.stop="
                                            cancelInlineReservation({
                                                reserve_id: reservation.id,
                                                store_id: storeId,
                                            })
                                        "
                                    >
                                        取消預約
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <div class="index-container" style="margin-top: 90px !important;">
            <div class="title d-none d-xl-block">
                候補預約
                <div v-if="isRelease" class="release-show">此為測試站，請至正式站操作</div>
            </div>
        </div>
        <div class="wrap">
            <BaseSelect
                title="排序方式"
                rootClass="arrangement-selector"
                name="arrangement"
                :options="arrangementList"
            />
            <transition appear name="fade" v-if="isListRequest">
                <NoData v-show="waitingList == undefined" />
            </transition>
            <transition appear name="fade" v-if="isListRequest">
                <div class="row reserve-container" v-show="waitingList != undefined">
                    <div class="reserve-wrap">
                        <div class="order-list-wrap">
                            <div class="order-title">候補預約<div v-if="isRelease" class="release-show">此為測試站，請至正式站操作</div></div>
                            <div class="row order-list-header">
                                <div class="col-2 text-center">服務時間</div>
                                <div class="col-1 text-center">客戶</div>
                                <div class="col-2 col-2-less text-center">連絡電話</div>
                                <div class="col-2">服務資訊</div>
                                <div class="col-1">芳療師</div>
                                <div class="col-2 text-center">候位序號</div>
                                <div class="col-2 col-2-more"></div>
                            </div>
                            <div
                                class="row order-list"
                                v-for="reservation in waitingList"
                                :key="reservation.id"
                                :class="{
                                    active: reservation.is_show_color == 1,
                                }"
                                @click="goToAroma(reservation)"
                            >
                                <div
                                    class="active-icon"
                                    v-show="
                                        reservation.is_show_color == 1
                                    "
                                >
                                    <img src="@/assets/tgd/reserve/date.svg" alt=""/>
                                </div>
                                <div class="col-2 text-center">
                                    <div class="flex_wrap">
                                        {{ reservation.time }}
                                        <div class="inline-data">
                                            <img src="@/assets/tgd/reserve/reserve.svg" alt=""/>
                                            <div class="info" v-show="reservation.customer_remark !== ''">
                                                <img src="@/assets/tgd/reserve/remark.svg" alt=""/>    
                                                <div class="info_content">
                                                    {{ reservation.customer_remark }}
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-1 text-center">
                                    {{ reservation.customer_name }}
                                    ({{ reservation.member.length}})
                                </div>
                                <div class="col-2 col-2-less text-center tel-color">
                                    {{ reservation.customer_phone }}
                                </div>
                                <div class="col-2 d-flex flex-column">
                                    <span
                                        class="py-1"
                                        v-for="(service, index) in reservation.service"
                                        :key="index"
                                    >
                                        {{ service }}
                                    </span>
                                </div>
                                <div class="col-1 d-flex flex-column">
                                    <span
                                        class="py-1"
                                        v-for="(member, index) in reservation.member"
                                        :key="index"
                                    >
                                        {{ member.member_name }}
                                        <span
                                            class="badge-color"
                                            v-if="member.type"
                                        >
                                            老點
                                        </span>
                                    </span>
                                </div>
                                <div class="col-2 text-center tel-color">
                                    {{ reservation.serial_number}}
                                </div>
                                <div class="col-2 col-2-more d-flex justify-content-center">
                                    <button
                                        type="button"
                                        class="btn btn-checkout"
                                        @click.stop="addToReservation(reservation.id)"
                                        v-if="reservation.is_show_inline_cancel == 0"
                                    >
                                        接單
                                    </button>
                                    <div
                                        class="btn btn_inline_cancel"
                                        v-if="reservation.is_show_inline_cancel == 1"
                                    >
                                        線上取消
                                    </div>
                                    <button
                                        type="button"
                                        class="btn btn-edit"
                                        @click.stop="
                                            cancelReservation({
                                                reserve_id: reservation.id,
                                                store_id: storeId,
                                            })
                                        "
                                    >
                                        <span v-if="reservation.is_show_inline_cancel == 0">不接單</span>
                                        <span v-else>取消候補</span>
                                        <!-- <span v-if="reservation.reserve_status == 2">取消候補</span> -->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
    <FixedOrderInfo/>
</template>

<script>
import { computed, watch, ref, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import { useForm } from "vee-validate";
import { useRouter } from 'vue-router';
import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";

import Logo from "@/components/includes/Logo.vue";
import TopTab from "@/components/includes/TopTab.vue";
import StartServiceLightbox from "@/components/lightbox/startService/StartServiceLightbox.vue";
import NoData from "@/components/includes/NoData.vue";
import useSwalCustom from "@/hooks/common/useSwalCustom";
import FixedOrderInfo from '@/components/inline/FixedOrderInfo';

export default {
    name: "Reservation",
    components: {
        Logo,
        TopTab,
        StartServiceLightbox,
        NoData,
        FixedOrderInfo
    },
    setup() {
        const { state, commit, dispatch } = useStore();
        const storeId = computed(() => state.auth.store_id);
        const { openStartServiceLightbox } = useHandleLightboxOpen();
        const isListRequest = ref(false);
        // 當前選取的芳療師
        const { setFieldValue, values } = useForm();
        watch(() => values.selected_aroma, (id) => {
            if(!id && id !== 0) return;

            commit("reservation/update", {
                key: "selectedAroma",
                value: id,
            });

            dispatch("reservation/getReservationList", {
                store_id: storeId.value,
                id,
            })
        });
        // 芳療師名單
        const aromatherapistList = computed(() => {
            
            let list = state.reservation.aromatherapistList.map(item =>{
                return {
                    id: item.id,
                    name: `${item.member_code}${item.nickname}`
                }
            });

            if (list !== undefined) {
                list.unshift({
                    id: 0,
                    name: "全部",
                });

                setFieldValue("selected_aroma", list[0].id);
            }

            return list;
        });

        // 預約列表
        let reservationList = computed(() => state.reservation.list);
        // inline候補列表
        let waitingList = computed(() => state.reservation.waitingList);
        let currentOpenId = computed(() => state.reservation.id);
        let currentTime = computed(() => state.reservation.time);
        
        const swalCustom = new useSwalCustom({
            title: "確定要取消預約嗎？",
            text: "取消預約後將無法恢復",
            icon: "warning",
            confirmButtonText: "確定",
        });

        const swalCustom2 = new useSwalCustom({
            title: "是否取消這筆線上預約?",
            text: "取消預約後將無法恢復",
            icon: "warning",
            confirmButtonText: "確定",
        });

        // 排序選項
        const arrangementList = ref([
            {
                'id' : 1,
                'name' : '依照預約時間排序'
            },
            {
                'id' : 2,
                'name' : '依照預約建立時間排序'
            }
        ]);

        // 依照所選方式排序資料
        watch(() => values.arrangement, (id) => {

            if (!waitingList.value || waitingList.value.length === 0) return;
            
            // 依照預約時間排序
            if(id == 1){
                waitingList.value.sort((a, b) => {
                    var dateA = new Date(a.time);
                    var dateB = new Date(b.time);
                    return dateA - dateB; 
                });
            }else{
                waitingList.value.sort((a, b) => {
                    var dateA = new Date(a.reserver_date);
                    var dateB = new Date(b.reserver_date);
                    return dateA - dateB; 
                });
            }
        });

        // 添加候補訂單到本日預約
        const addToReservation = async (reserveID) => {
            try {
                const res = await basePost("/api_reserve/inline_waiting_reserve", {
                    reserve_id : reserveID
                });

                dispatch("reservation/getReservationList", {
                    store_id: storeId.value,
                    id: values.selected_aroma,
                })
            } catch (err) {
                console.error(err);
            }
        }

        // 取得芳療師名單
        dispatch("reservation/getAromatherapistList", {
            store_id: storeId.value,
        });

        const openLightbox = (id, time) => {
            commit("reservation/update", { key: "id", value: id });
            commit("reservation/update", { key: "time", value: time });
            nextTick(()=> {
                openStartServiceLightbox();
            })
        };

        // 取消預約
        const cancelReservation = (params) => {
            swalCustom.fire({
                showCancelButton: true,
                cancelButtonText: "取消",
            }).then((info) => {
                if(info.isDismissed) return;

                dispatch("reservation/cancelReservation", params);
            });
        };

        // 取消inline預約
        const cancelInlineReservation = (params) => {
            swalCustom2.fire({
                showCancelButton: true,
                cancelButtonText: "取消",
            }).then((info) => {
                if(info.isDismissed) return;

                dispatch("reservation/cancelReservation", params);
            });
        };


        watch(reservationList, (list) => {
            if(!isListRequest.value) {
                isListRequest.value = true;
            }
        })

        onMounted(() => {
            if(!values.selected_aroma) return;

            dispatch("reservation/getReservationList", {
                store_id: storeId.value,
                id: values.selected_aroma,
            })
        })

        const router = useRouter();
        const goToAroma = (item) => {
            router.push({ 
                path: '/reservation_table/multi_aroma', 
                query: { 
                    id: item.id,
                    status: item.reserve_status
                } 
            });
            localStorage.setItem('reserve_type', item.reserve_type)
        }

        var isRelease = location.hostname === 'tgd-vue-release.muki001.com';

        return {
            reservationList,
            aromatherapistList,
            openLightbox,
            currentOpenId,
            currentTime,
            cancelReservation,
            cancelInlineReservation,
            storeId,
            isListRequest,
            goToAroma,
            isRelease,
            arrangementList, 
            addToReservation,
            waitingList
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/reset";
@import "~@/css/mixins";
@import "~@/css/grid";
.reservation {
    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
        }
    }
    .wrap {
        max-width: 1400px;
        margin: 0 auto;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        @include pad_pro_lg {
            padding: 0;
        }
        @include phone {
            padding: 20px;
        }

        .input-group {
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ffffff;
            border-radius: 11px;
            height: 53px;
            width: 300px;
            position: absolute;
            right: -5px;
            top: -67px;
            @include phone {
                height: 45px;
                position: relative;
                top: 0;
                right: 10px;
            }
            .input-group-text {
                background: transparent
                    linear-gradient(
                        180deg,
                        #ececec 0%,
                        #f9f9f9 35%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                border: none;
                border-radius: 11px 0px 0px 11px;
                padding: 15px;
                position: relative;
                font: normal normal bold 16px/21px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #000000;
                @include phone {
                    padding: 13px;
                }
            }
            .form-control {
                background: transparent
                    linear-gradient(
                        180deg,
                        #ececec 0%,
                        #f9f9f9 35%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                border: none;
                border-radius: 0px 11px 11px 0px;
                padding: 15px;
                font: normal normal 600 16px/22px Open Sans;
            }
            select {
                flex: 1;
                background: #f9f9f9
                    url("~@/assets/tgd/lightbox/select-arrow-box.svg") no-repeat
                    101%;
                border: none;
                border-radius: 11px;
                padding: 15px;
                font: normal normal bold 16px/22px Open Sans;
                letter-spacing: 0px;
                color: #000000;
                appearance: none;
                filter: contrast(100%);
                transition: all 0.3s;
                &:hover {
                    filter: contrast(130%);
                }
                @include pad_pro_lg {
                    padding: 12px;
                }
            }
            .custom-rounded {
                border-radius: 0px 11px 11px 0px;
            }
        }
        .reserve-container {
            justify-content: center;

            @include pad_pro_lg {
                overflow: overlay;
                justify-content: start;
            }
            .reserve-wrap {
                position: relative;
                width: 1400px;
            }
        }
        .order-list-wrap {
            margin-bottom: 15px;
            box-shadow: 0px 34px 77px #00000042;
            border: 1px solid #ffffff;
            border-radius: 15px;
            filter: contrast(100%);
            transition: all 0.3s;
            width: 100%;
            max-width: 1400px;
            &:hover {
                filter: contrast(105%);
            }
            @include pad_pro_lg {
                box-shadow: unset;
            }
        }
        .order-list-wrap {
            background: transparent
                linear-gradient(
                    122deg, 
                    #ffffff 0%, 
                    #d9d9d9 18%, 
                    #ffffff 100%
                )
                0% 0% no-repeat padding-box;
            padding: 75px;
            padding-bottom: 45px;
            font: normal normal bold 16px/21px Microsoft JhengHei;
            @include pad_pro_lg {
                padding: 0 30px;
                padding-bottom: 40px;
                padding-top: 60px;
            }
            @include pad_pro {
                margin-bottom: 15px;
            }
            @include pad {
                font-size: 14px;
            }
            .order-title {
                color: #a4641a;
                font-size: 17px;
                font-weight: bold;
                position: relative;
                padding-left: 15px;
                top: -40px;
                display: none;
                &:before {
                    position: absolute;
                    content: "";
                    top: -2px;
                    bottom: -2px;
                    left: 0;
                    border: 3px solid #a4641a;
                }
                @media (max-width: 1200px) {
                    display: block;
                }
            }
            .order-list-header {
                position: relative;
                top: -20px;
            }
            .order-list {
                cursor: pointer;
                background: transparent
                    linear-gradient(
                        180deg,
                        #ececec 0%,
                        #f9f9f9 35%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
                border: 1px solid #ffffff;
                border-radius: 11px;
                padding: 25px 0;
                align-items: center;
                margin-bottom: 15px;
                filter: contrast(100%);
                transition: all 0.3s;
                &:hover {
                    filter: contrast(90%);
                }
                .img-wrap {
                    display: flex;
                    padding-left: 20px;
                    position: relative;
                    top: 5px;
                    img {
                        filter: contrast(100%);
                        transition: all 0.3s;
                        &:hover {
                            filter: contrast(160%);
                        }
                    }
                }
                .btn {
                    width: 98px;
                    height: 42px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    filter: contrast(100%);
                    transition: all 0.3s;
                    &:hover {
                        filter: contrast(140%);
                    }
                }
                .btn-checkout {
                    background: transparent
                        linear-gradient(
                            293deg,
                            #a4641a 0%,
                            #a4641a 64%,
                            #ebc496 100%
                        )
                        0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border: 1px solid #ffffff85;
                    border-radius: 11px;
                    color: #fff;
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    margin-right: 5px;
                    @include pad {
                        font-size: 16px;
                    }
                }
                .btn-edit {
                    background: transparent
                        linear-gradient(
                            1deg,
                            #ececec 0%,
                            #ffffff 67%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border: 1px solid #ffffff;
                    border-radius: 11px;
                    color: #a4641a;
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    @include pad {
                        font-size: 16px;
                    }
                }
                .checked {
                    width: 98px;
                    height: 42px;
                    margin-right: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #a4641a;
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                }
                .tel-color {
                    color: #7d7d7d;
                }
                .badge-color {
                    background: #7d7d7d;
                    padding: 2px 12px;
                    margin-left: 10px;
                    border-radius: 12px;
                    font: normal normal bold 14px/19px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #ffffff;
                }
            }
            .order-list-header,
            .order-list {
                .col-2 {
                    &-less {
                        width: calc(100% / 6 - 30px);
                    }
                    &-more {
                        width: calc(100% / 6 + 30px);
                    }
                }
            }
            .active {
                background: #d67675 0% 0% no-repeat padding-box;
                color: #fff;
                position: relative;
                .active-icon {
                    position: absolute;
                    left: -17px;
                    background: #ffa5a5 0% 0% no-repeat padding-box;
                    border-radius: 50%;
                    width: 43px;
                    height: 43px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .tel-color {
                    color: #fff;
                }
            }
        }
    }

    .flex_wrap{
        display: inline-flex;
        align-items: center;
        .inline-data{
            display: inline-flex;
            position: relative;
            margin-left: 10px;
            .info{
                position: absolute;
                right: -5px;
                top: -5px;
                .info_content{
                    position: absolute;
                    left: calc(100% + 5px);
                    top: 0;
                    background: rgba(0,0,0,.8);
                    border: 1px #707070 solid;
                    width: 300px;
                    min-height: 120px;
                    padding: 13px;
                    border-radius: 4px;
                    display: none;
                    text-align: left;
                    color: #fff;
                }
                &:hover{
                    .info_content{
                        display: block;
                    }    
                }
            }
        }
    }
    .btn_inline_cancel{
        border-radius: 11px;
        background: #DFE2E8;  
        color: #0F2A68;
        font-weight: bold;
        margin-right: 5px;
    }
    .no-data{
        position: relative !important;
        top: auto !important;
        left: auto !important;
        transform: none !important;
    }
}

:deep(.aroma-selector) {
    max-width: 300px;
    margin-left: auto;
    position: relative;
    top: -72px;
    @media (max-width: 1200px){
        top: 0;
    }
}

:deep(.arrangement-selector) {
    max-width: 300px;
    margin-left: auto;
    position: relative;
    top: -72px;
    @media (max-width: 1200px){
        top: 0;
    }
}
</style>
